// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("chartkick")
require("chart.js")
require("trix")
require("@rails/actiontext")
require("@popperjs/core")
const bootstrap = require("bootstrap")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "scss/custom"

document.addEventListener("DOMContentLoaded", function(event) {
  const feather = require("feather-icons");
  feather.replace();

  window.copyValue = function(id) {
    var el = document.getElementById(id);
    el.type = 'text';
    el.select();
    document.execCommand("copy");
    el.type = 'hidden';
  };

  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => new bootstrap.Tooltip(el));
});
